import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Button,
} from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import HistoryIcon from '@mui/icons-material/History';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackBars } from '../../hooks';
import CompanyBlacklistComponent from '../cells/CompanyBlacklistComponent';
import MasterEntityNotesActions from '../drawer/MasterEntityNotesActions';
import FingoDialog from './FingoDialog';
import CreditLineLinearProgress from '../progress/CreditLineLinearProgress';
import {
  CompanyHasCredentialsCell,
  CustomerDocumentsCellAccordion,
} from '../cells';
import DicomComponent from '../dicom/DicomComponent';
import { AvatarCell } from '../dataGridCells';
import {
  MASTER_ENTITY_PROFILE,
  CREATE_CREDIT_LINE_EVALUATION_REQUESTS,
} from '../../graphql';
import { ControlledSelect } from '../selects';
import {
  ClientFactoringInformation,
  DebtorFactoringInformation,
  ClientOrderingInformation,
  ClientPaymentPlanInformation,
} from '../risk';
import LegalRepresentativesDialog from './LegalRepresentativeDialog';
import CompanyCessionsTooltip from '../cells/CompanyCessionTooltip';
import { formatMoney } from '../../helpers';
import { SummaryContactCard } from '../cards';
import { CONTACT_COLUMNS } from '../../constants/contact-columns';
import CreateContractRequestButton from '../contracts/CreateContractRequest';

const CompanyProfileDialog = ({ open, setOpen, masterEntityId, showDicom }) => {
  const [selectedTab, setSelectedTab] = useState('client');
  const [business, setBusiness] = useState('payment_plan');
  const [amortizationData, setAmortizationData] = useState(null);
  const [paymentPlanViewRendered, setPaymentPlanViewRendered] = useState(false);
  const { data, loading } = useQuery(MASTER_ENTITY_PROFILE, {
    variables: { masterEntityId },
    skip: !open,
  });
  const masterEntity = data?.getMasterEntity;
  const { addAlert } = useSnackBars();
  const [createCreditLineEvaluationRequests] = useMutation(
    CREATE_CREDIT_LINE_EVALUATION_REQUESTS,
    {
      refetchQueries: [MASTER_ENTITY_PROFILE],
      onCompleted: () => {
        addAlert({
          id: 'credit-line-evaluation-request',
          message: 'Solicitud de evaluación de línea creada',
          severity: 'success',
          color: 'success',
        });
      },
      onError: (error) => {
        addAlert({
          id: 'credit-line-evaluation-request-error',
          message: `Error al crear solicitud de evaluación de línea: ${error.message}`,
          severity: 'error',
          color: 'error',
        });
      },
    },
  );

  const handleAmortizationDataLoaded = useCallback(
    (dataPP) => {
      setAmortizationData(dataPP);
      if (!paymentPlanViewRendered) {
        setBusiness('factoring');
        setPaymentPlanViewRendered(true);
      }
    },
    [paymentPlanViewRendered],
  );

  const paymentPlanView = useMemo(
    () => (
      <ClientPaymentPlanInformation
        masterEntity={masterEntity}
        onDataLoaded={handleAmortizationDataLoaded}
      />
    ),
    [masterEntity, handleAmortizationDataLoaded],
  );

  const showClientFactoringInformationTab = useMemo(
    () => business === 'factoring' && selectedTab === 'client',
    [business, selectedTab],
  );
  const showClientOrderingInformationTab = useMemo(
    () => business === 'ordering' && selectedTab === 'client',
    [business, selectedTab],
  );
  const showClientPaymentplanInformationTab = useMemo(
    () => business === 'payment_plan' && selectedTab === 'client',
    [business, selectedTab],
  );

  const hasDicomCreatedAt = masterEntity?.currentDicom?.createdAt
    ?? masterEntity?.currentDicomPlatinum?.createdAt;
  const disabledDicomButton = hasDicomCreatedAt == null;

  const selectedTabComponent = useMemo(() => {
    if (loading) return <LinearProgress />;
    if (showClientFactoringInformationTab) {
      return (
        <ClientFactoringInformation
          masterEntity={masterEntity}
          amortizationData={amortizationData}
        />
      );
    }
    if (showClientOrderingInformationTab) {
      return (
        <ClientOrderingInformation
          masterEntity={masterEntity}
          amortizationData={amortizationData}
        />
      );
    }
    if (showClientPaymentplanInformationTab) {
      return paymentPlanView;
    }
    return (
      <DebtorFactoringInformation
        masterEntityId={masterEntityId}
        type="transmitter"
      />
    );
  }, [
    loading,
    showClientFactoringInformationTab,
    showClientOrderingInformationTab,
    masterEntity,
  ]);

  const creditLines = masterEntity?.creditlines.edges?.map((edge) => edge.node);

  return useMemo(
    () => (
      <FingoDialog
        title="Perfil Empresa"
        open={open}
        handleClose={setOpen}
        maxWidth="xl"
        fullWidth
        sx={{ zIndex: 1100 }}
      >
        {!masterEntity ? (
          <Stack sx={{ alignItems: 'center' }}>
            <CircularProgress size={30} />
          </Stack>
        ) : (
          <Grid container>
            <Grid
              container
              item
              xs={12}
              width="100%"
              borderRadius={5}
              bgcolor="background.light"
              py={2}
              px={2}
            >
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ color: '#323232' }}
                  >
                    {masterEntity.name} {masterEntity.displayNationalIdentifier}
                  </Typography>
                  <CompanyBlacklistComponent
                    masterEntity={masterEntity}
                    disableDisplay
                  />
                  {creditLines.map((creditLine) => (
                    <Stack
                      key={creditLine.id}
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        sx={{ padding: '4px 8px', minWidth: 'auto' }}
                        disabled={creditLine.status === 'EVALUATING'}
                        onClick={() => createCreditLineEvaluationRequests({
                          variables: {
                            masterEntity: masterEntityId,
                          },
                        })}
                      >
                        Evaluar línea
                      </Button>
                      <CreditLineLinearProgress creditLine={creditLine} />
                    </Stack>
                  ))}
                  {masterEntity.company && (
                    <CustomerDocumentsCellAccordion
                      masterEntity={masterEntity}
                    />
                  )}
                  <Stack direction="row" spacing={2} margin={2}>
                    <DicomComponent
                      isNaturalPersonRut={masterEntity.isNaturalPersonRut}
                      masterEntityId={masterEntityId}
                      label="Detalle Dicom"
                      disabled={disabledDicomButton}
                    />
                    {showDicom && <LegalRepresentativesDialog companyId={masterEntityId} />}
                    <CreateContractRequestButton companyId={masterEntityId} />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={2.5}>
                <Stack direction="row" spacing={1}>
                  <CompanyCessionsTooltip company={masterEntity?.company}>
                    <NotesIcon />
                  </CompanyCessionsTooltip>
                  <Tooltip title="Historial">
                    <HistoryIcon />
                  </Tooltip>
                  {showDicom && (
                    <Stack direction="row" sx={{ width: 10, height: 23 }}>
                      <MasterEntityNotesActions masterEntity={masterEntity} />
                    </Stack>
                  )}
                </Stack>
                <Stack
                  spacing={1}
                  direction="column"
                  sx={{ maxWidth: 150, marginTop: 3 }}
                >
                  <Typography variant="h6" sx={{ color: '#323232' }}>
                    Credenciales
                  </Typography>
                  {masterEntity.company ? (
                    <CompanyHasCredentialsCell company={masterEntity.company} />
                  ) : (
                    'Sin credenciales'
                  )}
                </Stack>
                <Stack
                  spacing={1}
                  direction="column"
                  sx={{ maxWidth: 90, marginTop: 1 }}
                >
                  <Typography variant="h6" sx={{ color: '#323232' }}>
                    Excedentes
                  </Typography>
                  <Typography variant="h6">
                    $
                    {formatMoney(
                      masterEntity.company?.currentSurplusBalanceValue,
                    )}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1} mt={3}>
                  <Stack direction="row">
                    <Typography
                      variant="h6"
                      sx={{ color: '#323232', width: '100%', marginTop: 1.5 }}
                    >
                      Ejecutivo Asignado
                    </Typography>
                    <AvatarCell
                      user={masterEntity.company?.executiveAssigned}
                    />
                  </Stack>
                  <Stack direction="row">
                    <Typography
                      variant="h6"
                      sx={{ color: '#323232', width: '100%', marginTop: 1.5 }}
                    >
                      Ejecutivo Ratificación
                    </Typography>
                    <AvatarCell user={masterEntity?.debtor?.ratificator} />
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={3.5}>
                <SummaryContactCard
                  masterEntity={masterEntity}
                  columns={CONTACT_COLUMNS}
                  showAllContactTypes
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: '100%', height: 600 }}>
                <Tabs
                  value={selectedTab}
                  onChange={(_, value) => setSelectedTab(value)}
                  centered
                >
                  <Tab label="Información como Cliente" value="client" />
                  <Tab
                    label="Información como Deudor"
                    value="debtor"
                    disabled={['payment_plan', 'ordering'].includes(business)}
                  />
                </Tabs>
                <ControlledSelect
                  value={business}
                  handleChange={(e) => setBusiness(e.target.value)}
                  inputLabel="Tipo Negocio"
                  label=""
                  options={[
                    { label: 'Factoring', value: 'factoring', disabled: false },
                    {
                      label: 'Ordering',
                      value: 'ordering',
                      disabled: selectedTab === 'debtor',
                    },
                    {
                      label: 'Planes de pago',
                      value: 'payment_plan',
                      disabled: selectedTab === 'debtor',
                    },
                  ]}
                />
                {selectedTabComponent}
              </Box>
            </Grid>
          </Grid>
        )}
      </FingoDialog>
    ),
    [masterEntity, selectedTabComponent, business, selectedTab],
  );
};

CompanyProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  masterEntityId: PropTypes.string.isRequired,
  showDicom: PropTypes.bool,
};

CompanyProfileDialog.defaultProps = {
  showDicom: true,
};

export default CompanyProfileDialog;

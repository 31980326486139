import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import { DicomShareholdersColumns } from '../../constants';

const DicomShareholders = ({ dicomModel }) => (
  <Stack
    direction="column"
    spacing={4}
    sx={{ marginTop: 5 }}
  >
    <FingoDataGrid
      rows={dicomModel?.shareholders ?? []}
      columns={DicomShareholdersColumns}
      serverFilters={false}
      hideFooter
      sx={{ minHeight: 250 }}
    />
  </Stack>
);

DicomShareholders.propTypes = {
  dicomModel: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    shareholders: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      createdAt: PropTypes.string,
      dateInformation: PropTypes.string,
      dateVinculation: PropTypes.string,
      dv: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      participation: PropTypes.string,
      rut: PropTypes.string,
      type: PropTypes.string,
    })),
  }),
};

DicomShareholders.defaultProps = {
  dicomModel: {},
};

export default DicomShareholders;

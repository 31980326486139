import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FingoDialog from './FingoDialog';
import useGetMasterEntity from '../../hooks/useGetMasterEntity';
import { CustomerDocumentsCell } from '../cells';

const CompanyDetailsDialog = ({ companyId, open, setOpen }) => {
  const masterEntity = useGetMasterEntity(companyId, !open);
  const closeDialog = useCallback(() => setOpen(false), []);
  return (
    <FingoDialog
      open={open}
      handleClose={closeDialog}
      title={masterEntity ? `${masterEntity.name}` : ''}
      fullWidth
    >
      { masterEntity ? (
        <Grid container direction="column">
          <Divider sx={{ marginBlock: 1 }}>Documentos descargables</Divider>
          <Grid container direction="row" alignItems="center">
            <CustomerDocumentsCell masterEntity={masterEntity} />
          </Grid>
        </Grid>
      ) : (
        <CircularProgress />
      )}
    </FingoDialog>
  );
};

CompanyDetailsDialog.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CompanyDetailsDialog;

import React from 'react';
import PropTypes from 'prop-types';
import CreateIcon from '@mui/icons-material/Create';
import DrawerHeader from './DrawerHeader';
import { ActionDrawerCell } from '../cells';
import MasterEntityNotesComponent from './MasterEntityNotesComponent';
import EvaluationCommentPropType from '../../propTypes/EvaluationComments';

const MasterEntityNotesActions = ({ masterEntity }) => (
  <ActionDrawerCell
    title={`Notas de ${masterEntity.name}`}
    actions={masterEntity.evaluationComments}
    headerComponent={DrawerHeader}
    contentComponent={MasterEntityNotesComponent}
    icon={CreateIcon}
    masterEntityId={masterEntity.id}
  />
);

MasterEntityNotesActions.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    evaluationComments: PropTypes.arrayOf(EvaluationCommentPropType).isRequired,
  }).isRequired,
};

export default MasterEntityNotesActions;

import React from 'react';
import PropTypes from 'prop-types';
import DicomDetail from './DicomDetail';
import { DICOM_PLATINUM_DETAILS, DICOM_DETAILS } from '../../graphql';

const DicomComponent = (
  { isNaturalPersonRut, masterEntityId, label, disabled },
) => {
  if (isNaturalPersonRut) {
    return (
      <DicomDetail
        query={DICOM_PLATINUM_DETAILS}
        queryResponse="dicomsPlatinum"
        masterEntityId={masterEntityId}
        label={label}
        disabled={disabled}
      />
    );
  }
  return (
    <DicomDetail
      query={DICOM_DETAILS}
      masterEntityId={masterEntityId}
      label={label}
      disabled={disabled}
      queryResponse="dicoms"
    />
  );
};

DicomComponent.propTypes = {
  isNaturalPersonRut: PropTypes.bool,
  label: PropTypes.string,
  masterEntityId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

DicomComponent.defaultProps = {
  isNaturalPersonRut: false,
  disabled: false,
  label: 'Detalle',
};

export default DicomComponent;

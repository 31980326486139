import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';

const DicomHeader = (
  { disabledShareholders,
    selectedTab,
    setSelectedTab,
    selectedDicomId,
    availableOptions,
    setSelectedDicomId,
  },
) => (
  <Stack direction="row" spacing={6} justifyContent="center">
    <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)} centered>
      <Tab label="Impagos" value="bulletins" />
      <Tab label="Sociedades" value="societies" />
      <Tab label="Accionistas" value="shareholders" disabled={disabledShareholders} />
      <Tab label="Consultas Externas" value="externalQueries" />
      <Tab label="Score Dicom" value="dicomScore" />
    </Tabs>
    <Stack spacing={0.5} alignItems="center">
      <Typography><b>Fecha de reporte</b></Typography>
      <Select
        id="report-selector"
        value={selectedDicomId}
        onChange={(e) => setSelectedDicomId(e.target.value)}
        variant="outlined"
        placeholder="Seleccione un reporte"
      >
        {availableOptions.map((option) => (
          <MenuItem
            key={option.key}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  </Stack>
);

DicomHeader.propTypes = {
  disabledShareholders: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  availableOptions: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
  })),
  selectedDicomId: PropTypes.string,
  setSelectedDicomId: PropTypes.func.isRequired,
};

DicomHeader.defaultProps = {
  selectedDicomId: '',
  availableOptions: [],
};

export default DicomHeader;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useIsMobile } from '../../hooks';
import { formatText } from '../../helpers/text-parser';
import FingoAlert from './alerts/FingoAlert';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '5rem',
    margin: 'auto',
    marginBottom: '0.5rem',
    width: '100%',
  },
}));

const FingoDialog = (props) => {
  const {
    open,
    handleClose,
    title,
    subtitle,
    icon: Icon,
    dialogAlert,
    setDialogAlert,
    children,
    dialogActionButton,
    maxWidth,
    hideCloseButton,
    paddingContent,
    backgroundColor,
    ...dialogProps
  } = props;
  const isMobile = useIsMobile();
  const classes = useStyles();
  const padding = useMemo(() => {
    if (paddingContent !== null) return paddingContent;
    return isMobile ? 4 : 6;
  }, [isMobile, paddingContent]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: 4,
          maxWidth: maxWidth || 770,
        },
      }}
      {...dialogProps}
    >
      <DialogTitle
        component="div"
        sx={{
          borderRadius: '4px 4px 0 0',
          bgcolor: 'primary.main',
          py: 3,
        }}
      >
        {typeof title === 'string' ? (
          <Typography color="white" align="center" variant="h4">
            {title}
          </Typography>
        ) : (
          title
        )}
        {!hideCloseButton && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
            }}
            color="fingoWhite"
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <FingoAlert alert={dialogAlert} setAlert={setDialogAlert} />
      <DialogContent sx={{ p: padding, backgroundColor }}>
        {Icon && <Icon className={classes.icon} />}
        {subtitle && (
          <Typography variant="h2" align="center" sx={{ mb: 2, px: 2 }}>
            {formatText(subtitle)}
          </Typography>
        )}
        {children}
      </DialogContent>
      {dialogActionButton && <DialogActions disableSpacing>{dialogActionButton}</DialogActions>}
    </Dialog>
  );
};

FingoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.string,
  dialogAlert: PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.string,
    show: PropTypes.bool,
  }),
  setDialogAlert: PropTypes.func,
  icon: PropTypes.elementType,
  children: PropTypes.node,
  dialogActionButton: PropTypes.element,
  maxWidth: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  paddingContent: PropTypes.number,
  backgroundColor: PropTypes.string,
};
FingoDialog.defaultProps = {
  dialogActionButton: null,
  maxWidth: '',
  title: '',
  subtitle: '',
  icon: undefined,
  hideCloseButton: false,
  children: null,
  dialogAlert: { message: '', severity: '', show: false },
  setDialogAlert: () => {},
  paddingContent: null,
  backgroundColor: null,
};

export default FingoDialog;

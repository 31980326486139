import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InformationTooltip } from '../tooltips';

const DrawerHeader = ({ title, subtitle, headerTooltipText }) => (
  <Grid sx={{ marginBottom: 3 }}>
    <Stack direction="row" spacing={1}>
      <Typography variant="h4" color="primary">
        {title}
      </Typography>
      {headerTooltipText && <InformationTooltip title={headerTooltipText} />}
    </Stack>
    {subtitle
      && (
      <Typography variant="h2" color="primary">
        {subtitle}
      </Typography>
      )}
  </Grid>
);

DrawerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  headerTooltipText: PropTypes.string,
};

DrawerHeader.defaultProps = {
  subtitle: null,
  headerTooltipText: null,
};

export default DrawerHeader;

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import DocumentChip from '../cells/DocumentsChip';
import useGetPurchaseOrder from '../../hooks/useGetPurchaseOrder';
import FingoDialog from './FingoDialog';
import { PURCHASE_ORDER_DOCUMENT_PROFILE } from '../../graphql/ordering/queries';

const DocumentsPurchaseOrderDialog = ({ purchaseOrderId, open, setOpen }) => {
  const closeDialog = useCallback(() => setOpen(false), []);

  const { loading, purchaseOrder: getPurchaseOrder } = useGetPurchaseOrder(
    purchaseOrderId,
    PURCHASE_ORDER_DOCUMENT_PROFILE,
  );

  const purchaseOrder = useMemo(() => ({ ...getPurchaseOrder, label: 'PDF OC', documentType: 'PDF' }), [getPurchaseOrder]);

  return (
    <FingoDialog
      open={open}
      handleClose={closeDialog}
      title={!loading ? `OC ${purchaseOrder.orderNumber} de ${purchaseOrder.company?.name}` : 'OC'}
      fullWidth
    >
      {loading && <CircularProgress />}
      {!loading && (
        <Stack container direction="column">
          <Divider sx={{ marginBlock: 1 }}>Equipo Fingo</Divider>
          <Grid container direction="row" alignItems="center">
            <Avatar
              alt={purchaseOrder.company.executiveAssigned?.firstName}
              src={purchaseOrder.company.executiveAssigned?.picture?.url}
              sx={{ margin: 1 }}
            />
            <Typography component="span">
              {`${purchaseOrder.company.executiveAssigned?.completeName} es su ejecutivo/a`}
            </Typography>
          </Grid>
          <Divider sx={{ marginBlock: 1 }}>Documentos descargables</Divider>
          <DialogContent>
            {purchaseOrder.pdfDocument ? (
              <DocumentChip
                document={purchaseOrder}
                url={purchaseOrder.pdfDocument}
                key={purchaseOrder.id}
              />
            ) : (
              <Typography variant="body2" align="center">No hay documentos cargados</Typography>
            )}
          </DialogContent>
        </Stack>
      )}
    </FingoDialog>
  );
};

DocumentsPurchaseOrderDialog.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default DocumentsPurchaseOrderDialog;

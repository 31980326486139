import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import UploadDropZoneDialogContainer from '../containers/UploadDropZoneDialogContainer';

const UploadDropZoneDialog = (props) => {
  const {
    open,
    setOpen,
    files,
    setFiles,
    message,
    downloadTemplate,
    onCompleted,
    loading,
    buttonMessage,
  } = props;
  const [error, setError] = useState(null);

  const onClose = () => { setOpen(false); setFiles([]); setError(''); };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{ background: 'primary' }}
      >
        Carga de Documentos
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ width: '440px', height: '320px', paddingBottom: 0 }}>
        <Typography paddingY="18px" variant="caption" component="div">{message}</Typography>
        <Box sx={{ height: '240px', width: '100%' }}>
          <UploadDropZoneDialogContainer
            files={files}
            setFiles={setFiles}
            errorMutation={error}
            setErrorMutation={setError}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column' }}>
        {files.length
          ? (
            <LoadingButton
              variant="contained"
              onClick={onCompleted}
              loading={loading}
              loadingPosition="start"
              startIcon={<CloudUpload />}
              disabled={loading}
            >
              {buttonMessage}
            </LoadingButton>
          )
          : downloadTemplate && (
          <Button onClick={downloadTemplate}>
            Descarga la plantilla en .XLSX
          </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};

UploadDropZoneDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onCompleted: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  downloadTemplate: PropTypes.func,
  buttonMessage: PropTypes.string,
};

UploadDropZoneDialog.defaultProps = {
  downloadTemplate: null,
  buttonMessage: 'Subir carga masiva',
};

export default UploadDropZoneDialog;

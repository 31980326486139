import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useBooleanState } from '../../hooks';
import DicomBulletins from './DicomBulletins';
import DicomSocieties from './DicomSocieties';
import { FingoDialog } from '../dialogs';
import DicomHeader from './DicomHeader';
import DicomShareholders from './DicomShareholders';
import DicomExternalQueries from './DicomExternalQueries';
import DicomScore from './DicomScore';
import { formatGraphQlDate } from '../../helpers';

const DicomDetail = (
  { query, masterEntityId, label, disabled, queryResponse },
) => {
  const [selectedDicomId, setSelectedDicomId] = useState(null);
  const [selectedTab, setSelectedTab] = useState('bulletins');
  const [open, setOpen] = useBooleanState();
  const [loadDicomDetail, { data, loading, error }] = useLazyQuery(
    query,
    {
      variables: { masterEntityId },
      fetchPolicy: 'network-only',
      onCompleted: ({ getMasterEntity }) => {
        const dicomsList = getMasterEntity[queryResponse];
        setSelectedDicomId(dicomsList[dicomsList.length - 1].id);
        setOpen(true);
      },
    },
  );
  const selectedDicom = useMemo(() => (data?.getMasterEntity[queryResponse].find(
    (dicom) => dicom.id === selectedDicomId,
  )), [selectedDicomId]);

  const availableOptions = data?.getMasterEntity[queryResponse].map((dicom) => ({
    timestamp: moment(dicom.createdAt),
    label: formatGraphQlDate(moment(dicom.createdAt)),
    value: dicom.id,
    key: `dicom-${dicom.id}`,
  })).sort((a, b) => a.timestamp.diff(b.timestamp));

  const TableComponent = useMemo(() => {
    if (selectedTab === 'bulletins') return (DicomBulletins);
    if (selectedTab === 'societies') return (DicomSocieties);
    if (selectedTab === 'externalQueries') return (DicomExternalQueries);
    if (selectedTab === 'dicomScore') return (DicomScore);
    return (DicomShareholders);
  }, [selectedTab, selectedDicomId]);

  return (
    <>
      <LoadingButton
        onClick={loadDicomDetail}
        loading={loading}
        variant="contained"
        disabled={error || disabled}
        size="small"
      >
        {error ? 'Error ' : ''}{label}
      </LoadingButton>
      <FingoDialog
        title={`Dicom ${data?.getMasterEntity.name}`}
        open={open}
        handleClose={() => setOpen(!open)}
        maxWidth="lg"
        fullWidth
      >
        <DicomHeader
          disabledShareholders={queryResponse === 'getDicomPlatinumDetails'}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedDicomId={selectedDicomId}
          availableOptions={availableOptions}
          setSelectedDicomId={setSelectedDicomId}
        />
        <TableComponent dicomModel={selectedDicom} />
      </FingoDialog>
    </>
  );
};

DicomDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  masterEntityId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  queryResponse: PropTypes.string.isRequired,
};

DicomDetail.defaultProps = {
  disabled: false,
};

export default DicomDetail;
